<template>
  <div id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>

<style src='./burger.css' scoped></style>

<script>
import { mutations } from "@/services/Store.js";

export default {
  computed: {
    isBurgerActive() {
      return mutations.getNavStatus();
    },
  },
  methods: {
    toggle() {
      mutations.toggleNav();
    },
  },
};
</script>


<template>
  <div class="page-container">
    <div class="page-content">
      <div class="buttons-container" v-if="isTeacher && displayEdition">
        <button class="buttonPrimary" v-on:click="openModal('CreateSupport', { typeSupport: 1 })">
          + Page
        </button>
         <button class="buttonPrimary" v-on:click="openModal('CreateSupport', { typeSupport: 2 })">
          + Lien
        </button>
      </div>

      <div class="supports-container">
        <div
          class="no-support-text"
          v-if="supportsLoading && supports.length == 0"
        >
          Aucun support à afficher
        </div>
        <div
          v-for="currentSupport in supports"
          :key="currentSupport.id"
          class="item-masonry course-container"
        >
          <a
            class="miniature-course pointage"
            :href="getURLToPage(currentSupport)"
            v-on:click="redirectToPage($event, currentSupport)"
            draggable="false"
          >
            <img
              :src="getMiniature(currentSupport)"
              alt=""
              draggable="false"
              @error="errorImageLoading"
            />
          </a>

          <div class="support-type" v-html="formatTypeCourse(currentSupport)"></div>

          <div class="icons-container" v-if="isTeacher && displayEdition">
            <i class="icon-shortcut" v-if="currentSupport.Supporttype.titre == 'Cours' || currentSupport.Supporttype.titre =='TD'" v-on:click="openModal('SupportReponses', { idSupport: currentSupport.id })"></i>
            <i class="icon-user" v-on:click="openModal('SupportAccess', { idSupport: currentSupport.id })"></i>
            <i class="icon-settings" v-if="currentSupport.url" v-on:click="openModal('EditLink', { idSupport: currentSupport.id })"></i>
            <i class="icon-settings" v-else v-on:click="openModal('EditPage', { idSupport: currentSupport.id })"></i>
            <i class="icon-delete" v-on:click="openModal('DeleteSupport', { idSupport: currentSupport.id })"></i>
          </div>

          <a
            :href="getURLToPage(currentSupport)"
            class="course-title-container pointage"
            v-on:click="redirectToPage($event, currentSupport)"
            draggable="false"
          >
            <div
              class="title-container"
              v-html="formatTitleCourse(currentSupport)"
            ></div>
          </a>
        </div>
      </div>

      <CreateSupportModal @refresh="getSupportsFromTheme()"/>
      <EditPageModal @refresh="getSupportsFromTheme()"/>
      <EditLinkModal @refresh="getSupportsFromTheme()"/>
      <DeleteSupportModal @refresh="getSupportsFromTheme()"/>
      <SupportAccessModal @refresh="getSupportsFromTheme()"/>
      <SupportReponsesModal @refresh="getSupport()"/>
    </div>
  </div>
</template>

<style src='./themesPage.css' scoped></style>

<script>
import Api from "@/services/Api";
import Auth from "@/mixins/Auth";

import router from "@/router";

import CreateSupportModal from "@/modals/CreateSupportModal/CreateSupportModal";

import EditPageModal from "@/modals/EditPageModal/EditPageModal";
import EditLinkModal from "@/modals/EditLinkModal/EditLinkModal";

import DeleteSupportModal from "@/modals/DeleteSupportModal/DeleteSupportModal";
import SupportAccessModal from "@/modals/SupportAccessModal/SupportAccessModal";
import SupportReponsesModal from "@/modals/SupportReponsesModal/SupportReponsesModal";

export default {
  name: "ThemesPage",
  data() {
    return {
      initialMouseLocation: {},
      supports: [],
      supportsLoading: false,

      isTeacher: false,

      displayEdition: false,
    };
  },
  components: {
    CreateSupportModal,

    EditPageModal,
    EditLinkModal,

    DeleteSupportModal,
    SupportAccessModal,
    SupportReponsesModal,
  },
  methods: {
    openModal (modalName, params) {
      this.emitter.emit('openModal' + modalName, params);
    },
    errorImageLoading: function (event) {
      event.target.src = "/images/undefined.svg";
    },
    getSupportsFromTheme: async function () {
      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token"),
        },
      };

      let theme = null;
      try {
        theme = await Api().get(
          "/themes/" + this.$route.params.theme,
          configUrl
        );
      } catch (e) {
        router.push("/404");
      }

      document.title = theme.data.theme.titre;

      try {
        let responseSupports = await Api().get(
          "/themes/" + theme.data.theme.id + "/supports",
          configUrl
        )
        this.supports = responseSupports.data.supports
        this.supportsLoading = true
      } catch (e) {
        router.push("/403")
      }
    },
    redirectToPage: function (event, support) {
      event.preventDefault();
      if (support.url) {
        window.open(support.url);
      } else {
        let urlPage =
          "/supports/" + this.$route.params.theme + "/" + support.slugtitre;

        if (router.currentRoute.path !== urlPage) {
          router.push(urlPage);
        }
      }
    },
    getURLToPage: function (support) {
      let urlPage = "";
      if (support.url) {
        urlPage = support.url;
      } else {
        urlPage =
          "/supports/" + this.$route.params.theme + "/" + support.slugtitre;
      }

      return urlPage;
    },
    getMiniature: function (support) {
      if (
        Object.prototype.hasOwnProperty.call(support, "miniatureName") &&
        support.miniatureName !== null
      ) {
        return (
          process.env.VUE_APP_CDN_PATH +
          "/supports/" +
          support.id +
          "/cover/" +
          support.miniatureName
        );
      } else {
        return "/images/undefined.svg";
      }
    },
    formatTitleCourse: function (course) {
      let textTitle = "";
      if (course) {
        if (this.isTeacher && this.displayEdition)
          textTitle += course.ordre + " - ";
      }
      textTitle += course.titre;
      return textTitle;
    },
    formatTypeCourse: function (course) {
      let textTitle;
      if (course && course.Supporttype) {
        textTitle = course.Supporttype.titre;
      }
      return textTitle;
    },
  },
  created: async function () {
    let status = await Auth.methods.getAuthenticationStatus();
    this.isTeacher = status.typeUser === "teacher";
  },
  async mounted() {
    this.getSupportsFromTheme();

    let mode = localStorage.getItem("page-mode")
      ? localStorage.getItem("page-mode")
      : null;
    if (mode === "editing") {
      this.displayEdition = true;
    } else {
      this.displayEdition = false;
    }

    this.emitter.on("changeMode", (currentMode) => {
      if (currentMode === "editing") {
        this.displayEdition = true;
      } else {
        this.displayEdition = false;
      }
    });
  },
  watch: {
    $route() {
      if (this.$route.name === "ThemesPage") {
        this.getSupportsFromTheme();
      }
    },
  },
};
</script>

import { reactive } from "vue"

export const store = reactive({
    navOpen: window.innerWidth > 800
});

export const mutations = {
    openNav() {
        store.navOpen = true;
    },
    closeNav() {
        store.navOpen = false;
    },
    toggleNav() {
        store.navOpen = !store.navOpen;
    },
    getNavStatus() {
        return store.navOpen
    }
};

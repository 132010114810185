import Api from '@/services/Api'
import router from '@/router'

import Auth from '@/mixins/Auth'

import draggable from 'vuedraggable'

import CreateAreaModal from '@/modals/CreateAreaModal/CreateAreaModal'
import EditAreaModal from '@/modals/EditAreaModal/EditAreaModal'

import CreateAreaCssModal from '@/modals/CreateAreaCssModal/CreateAreaCssModal'
import EditAreaCssModal from '@/modals/EditAreaCssModal/EditAreaCssModal'

import CreateBlockTitreModal from '@/modals/CreateBlockTitreModal/CreateBlockTitreModal'
import EditBlockTitreModal from '@/modals/EditBlockTitreModal/EditBlockTitreModal'

import CreateBlockModal from '@/modals/CreateBlockModal/CreateBlockModal'
import EditBlockModal from '@/modals/EditBlockModal/EditBlockModal'
import DeleteBlockModal from '@/modals/DeleteBlockModal/DeleteBlockModal'

import CreateCodeModal from '@/modals/CreateCodeModal/CreateCodeModal'
import EditCodeModal from '@/modals/EditCodeModal/EditCodeModal'

import AreaReponse from '@/components/AreaReponse/AreaReponse'
import AreaHtml from '@/components/AreaHtml/AreaHtml'
import AreaCss from '@/components/AreaCss/AreaCss'


export default {
  name: 'SupportPage',
  data () {
    return {     
      currentSupport: {},
      currentSupportLoading: false,

      blocksSommaire: [],

      isTeacher: false,
      showReponses: true,

      showBar: 'show',

      dragging: false,

      displayEdition: false,
      visibilityAnswer: true
    }
  },
  components: {

    CreateAreaCssModal,
    EditAreaCssModal,

    CreateAreaModal,
    EditAreaModal,

    CreateBlockTitreModal,
    EditBlockTitreModal,

    CreateBlockModal,
    EditBlockModal,
    DeleteBlockModal,

    CreateCodeModal,
    EditCodeModal,

    AreaReponse,
    AreaHtml,
    AreaCss,

    draggable
  },
  methods: {
    errorImageLoading: function (event) {
      if(this.currentSupportLoading)
        event.target.src = "/images/undefined.svg"
    },
    openModal (modalName, params) {
      this.emitter.emit('openModal' + modalName, params);
    },
    scrollToElement (id) {
      const el = document.getElementById(id);
      
      if (el) {
        if (el[0]) {
          el[0].scrollIntoView({behavior: 'smooth'})
        } else {
          el.scrollIntoView({behavior: 'smooth'})
        }
      }
    },
    getElementId: function (elt) {
      return elt.id
    },
    updateOrderBlock: function (evt) {
      let data = {
        id: evt.moved.element.id,
        order: evt.moved.newIndex + 1
      }

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks/editBlock', data, configUrl)
        .then(() => {
          this.getSupport()
        })
        .catch(() => {
        })
    },
    saveReponse: async function (event, idBlock) {
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }
      let data = {
        idBlock: idBlock,
        content: event.target.value
      }
      await Api().post('/blocks/saveReponse', data, configUrl)
      this.getSupport()
    },
    getSupport: async function () {
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        },
        params: {
          slug: this.$route.params.course
        }
      }

      Api().get('/supports/getSupportBySlug', configUrl).then(async (response) => {
        response.data.course.Blocs = this.getFormattedTitles(response.data.course.Blocs)
        this.currentSupport = response.data.course
        
        configUrl = {
          headers: {
            authorization: 'Token ' + localStorage.getItem('user-token')
          },
          params: {
            idSupport: this.currentSupport.id
          }
        }

        let reponseBlocks = await Api().get('/blocks/titres', configUrl)
        this.blocksSommaire = this.getFormattedTitles(reponseBlocks.data.blocks);

        document.title = this.currentSupport.titre
        this.currentSupportLoading = true
      })
        .catch((e) => {
          if (e.response && e.response.status == "403") {
            router.push('/403')
          }
          else {
            router.push('/404')
          }
        })
    },
    getFormattedTitles: function (blocs) {

      for(let block of blocs) {
        if (block.Bloctype && (block.Bloctype.titre === 'title1' || block.Bloctype.titre === 'title2')) {
          block.title = block.contenu
        }
      }
      return blocs
    },
    redirectToSupport: function (event, support) {
      event.preventDefault()
      let pagePath = '/supports/' + this.$route.params.theme + '/' + support.slugtitre

      if (router.currentRoute.path !== pagePath) {
        router.push(pagePath)
      }
    },
    getURLToSupport: function (support) {
      return '/supports/' + this.$route.params.theme + '/' + support.slugtitre
    },
    redirectToPage: function (event) {
      event.preventDefault()

      const pagePath = event.srcElement.pathname
      if (router.currentRoute.path !== pagePath) {
        router.push(pagePath)
      }
    },
    getMiniature: function (support) {
      if (!this.currentSupportLoading)
        return
      if (Object.prototype.hasOwnProperty.call(support, 'miniatureName') && support.miniatureName !== null) {
        let pathImage = process.env.VUE_APP_CDN_PATH + '/supports/' + support.id + '/cover/' + support.miniatureName
        return pathImage
      } else {
        return '/images/undefined.svg'
      }
    },
    getReponse: function (block) {
      if (block.Reponses[0] && block.Reponses[0].contenu) {
        return block.Reponses[0].contenu
      }
    },
    formatTitleCourse: function (course) {
      if (course.titre && course.ordre) {
        let textTitle = course.Supporttype.titre + ' - ' + course.titre

        return textTitle
      }
    },
    toggleBar: function () {
      if (this.showBar === 'showed') {
        this.showBar = 'hidden'
        localStorage.setItem('summary-bar', 'hidden')
      } else {
        this.showBar = 'showed'
        localStorage.setItem('summary-bar', 'showed')
      }
    },
    getBlocContainerClass: function (bloc) {
      let classCSS = ''

      if (bloc) {
        if (bloc.Bloctype && bloc.Bloctype.titre.includes('title')) {
          classCSS = 'bloc-container-title '
        } else if (bloc.Bloctype && bloc.Bloctype.titre.includes('area')) {
          classCSS = 'bloc-container-area '
        }
        else if (bloc.Bloctype && bloc.Bloctype.titre.includes('instructions')) {
          classCSS = 'bloc-container-instructions '
        }
      }

      if (this.dragging === true) {
        classCSS += 'bordered-blocks'
      } else if (this.displayEdition === true) {
        classCSS += 'selectable-block'
      }
      return classCSS
    },
    getSideBarClass: function () {
      let classCSS = ''
      if (this.showBar === 'inithidden') {
        classCSS = 'hidden'
      } else if (this.showBar === 'hidden') {
        classCSS = 'hidden animate'
      } else {
        classCSS = 'animate'
      }
      return classCSS
    },
    getAnswerClass: function () {
      let classCSS = 'bloc-content bloc-form'

      if (this.visibilityAnswer === 'hidden' && this.isTeacher) {
        classCSS += ' hidden'
      }

      return classCSS
    }
  },
  created: async function () {
    let status = await Auth.methods.getAuthenticationStatus()
    this.isTeacher = status.typeUser === 'teacher'

    if (this.$route.params.course) {
      this.getSupport()
    } else {
      this.currentSupport = {}
    }

    let mode = localStorage.getItem('page-mode') ? localStorage.getItem('page-mode') : null

    if (mode === 'editing') {
      this.displayEdition = true
    } else {
      this.displayEdition = false
    }

    let bar = localStorage.getItem('summary-bar') ? localStorage.getItem('summary-bar') : null
    if (bar === 'hidden') {
      this.showBar = 'inithidden'
    } else {
      this.showBar = 'showed'
    }

    let visibilityAnswer = localStorage.getItem('answer-visibility') ? localStorage.getItem('answer-visibility') : null
    if (visibilityAnswer === 'hidden') {
      this.visibilityAnswer = 'hidden'
    } else {
      this.visibilityAnswer = 'showed'
    }

    this.emitter.on("changeMode", currentMode => {
      if (currentMode === 'editing') {
        this.displayEdition = true
      } else {
        this.displayEdition = false
      }
    })

    this.emitter.on("changeAnswerVisibility", currentMode => {
      if (currentMode !== null) {
        this.visibilityAnswer = currentMode
      }
    })
  },
  watch: {
    $route () {
      if (this.$route.params.course) {
        this.getSupport()
        this.$refs.page.scrollTo(0, 0)
      } else {
        this.currentSupport = {}
      }
    }
  }
}
<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>
      <h3 v-if="typeSupport === 1">Création d'une page</h3>
      <h3 v-if="typeSupport === 2">Création d'un lien</h3>
    </template>

    <template v-slot:body>
    </template>

    <template v-slot:footer>
      <form id='form-connexion' ref='form' v-on:submit='createSupport'>
        <div class='form-content'>
          <div class='container-champ'>
            <label class='label-champ'>Titre : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='title' autocomplete='off'>
          </div>

          <div v-if="typeSupport === 2" class='container-champ'>
            <label class='label-champ'>URL : </label>
            <input spellcheck='false' class='textfield-champ' type='text' name='url' autocomplete='off'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Miniature : </label>
            <div>
              <input type="file" id="upload-button" ref="upload-button" v-on:change="handleFileUpload()">
            </div>
            <div class="container-miniature"><img :src="url"></div>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' name='order' autocomplete='off' required value='1'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Type de support : </label>
            <div class="formfield-select--container">
              <select name='idSupport'>
                  <option v-for="supporttype in supporttypes" :key="supporttype.id" :value='supporttype.id'>
                  {{ supporttype.titre }}
                </option>
              </select>
            </div>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Theme : </label>
            <div class="formfield-select--container">
              <select name='themeId'>
                <option v-for="theme in themes" :key="theme.id" :value='theme.id'>
                  {{ theme.titre }}
                </option>
              </select>
            </div>
          </div>

          <div class='buttons-container'>
            <input type='submit' class='buttonPrimary' value='Valider'/>
            <a @click="closeModal">
              Annuler
            </a>
          </div>
        </div>
      </form>
    </template>
  </BasicModal>
</template>

<style src='./createSupportModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import ApiCdn from '@/services/ApiCdn'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'CreateSupportModal',
  data () {
    return {
      typeSupport: 0,
      miniature: null,
      url: '',
      defaulturl: '/images/undefined.svg',
      showedModal: false,
      themes: [],
      supports: []
    }
  },
  components: {
    BasicModal
  },
  methods: {
    handleFileUpload: function () {
      this.miniature = this.$refs['upload-button'].files[0]

      if (this.miniature && this.miniature !== null) {
        this.url = URL.createObjectURL(this.miniature)
      } else {
        this.url = this.defaulturl
      }
    },
    createSupport: async function (e) {
      e.preventDefault()

      if (!e.target.elements.title.value || e.target.elements.title.value === null) {
        return
      }

      function getBase64 (file) {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
      }

      let base64 = null
      if (this.miniature != null) {
        base64 = await getBase64(this.miniature)
      }

      let data = {
        titre: e.target.elements.title.value,
        ordre: e.target.elements.order.value,
        supporttypeId: e.target.elements.idSupport.value,
        themeId: e.target.elements.themeId.value
      }

      if (e.target.elements.url) {
        data.url = e.target.elements.url.value
      }

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/supports', data, configUrl)
        .then((supportData) => {
          let support = supportData.data.support
          ApiCdn().post('/supports', { miniature: base64, id: support.id }, configUrl)
          .then(async(fileNameData) => {
            let data = {
              id: support.id,
              titre: support.titre,
              miniatureName: fileNameData.data.fileName,
              ordre: support.ordre,
              supporttypeId: support.supporttypeId,
              themeId: support.themeId
            }
            await Api().put('/supports', data, configUrl)
            this.closeModal(null)
            this.$emit('refresh', true)
          })
          .catch(() => {
            this.closeModal(null)
            this.$emit('refresh', true)
          })         
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.showedModal = false
    },
    showModal: function (typeSupport) {
      this.typeSupport = typeSupport
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      this.url = this.defaulturl
      this.miniature = null

      Api().get('/themes', configUrl)
        .then((response) => {
          this.themes = response.data.themes

          Api().get('/supporttypes', configUrl)
            .then((response) => {
              this.supporttypes = response.data.supporttypes
              this.showedModal = true
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    this.emitter.on("openModalCreateSupport", params => {
      this.showModal(params.typeSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>

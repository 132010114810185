<template>
  <div class="page-container">
    <div class='page-background'>
      <form id='form' v-on:submit='sendAccountInfos'>

        <div class='title-form'>
          Initialisation du compte
        </div>

        <div class='infos-password'>
          <img class='info-icon' src='/images/icone_info.png' alt="">
          <div>
            Le nouveau mot de passe doit :
            <ul>
              <li>comporter au moins huit caractères.</li>
              <li>contenir au moins un chiffre.</li>
              <li>contenir au moins une minuscule et une majuscule.</li>
            </ul>
          </div>
        </div>

        <div class='container-field'>
          <label class='label_champ'>E-mail :</label>
          <input spellcheck='false' class='textfield_champ' type='text' name='email' placeholder='email' autocomplete='off' :value="user.email">
        </div>

        <div class='container-field'>
          <label class='label_champ'>Date de naissance :</label>
          <input spellcheck='false' class='textfield_champ' type='date' name='dateNaissance' placeholder='JJ/MM/AAAA' autocomplete='off' :value="user.dateNaissance">
        </div>

        <div class='container-field'>
          <label class='label_champ'>Nouveau mot de passe :</label>
          <input type='password' class='textfield_champ' name='password' autocomplete='new-password'/>
        </div>

        <div class='container-field'>
          <label class='label_champ'>Confirmer nouveau mot de passe :</label>
          <input type='password' class='textfield_champ' name='repassword' autocomplete='new-password'/>
        </div>

        <label class='label-error'>{{ message }}</label>

        <div class='container-buttons'>
          <a href='/' v-on:click='redirect'>Retour</a>
          <input type='submit' class='buttonPrimary' value='Envoyer'/>
        </div>
      </form>
    </div>
  </div>
</template>

<style src='./initAccountPage.css' scoped></style>

<script>
import Api from '@/services/Api'
import router from '@/router'
import moment from 'moment/moment'

export default {
  name: 'InitAccountPage',
  data () {
    return {
      message: '',
      user: {},
      configUrl: {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }
    }
  },
  methods: {
    getUserData: function () {
      Api().get('/users/current', this.configUrl)
        .then((response) => {
          this.user = response.data.user
        })
        .catch(() => {
          router.push('/')
        })
    },
    sendAccountInfos: function (e) {
      e.preventDefault()

      let param = {
        password: e.target.elements.password.value,
        repassword: e.target.elements.repassword.value,
        email: e.target.elements.email.value,
        dateNaissance: e.target.elements.dateNaissance.value
      }

      Api().put('/users/' + this.user.id, param, this.configUrl)
        .then(() => {
          if (router.currentRoute.path !== '/home') {
            router.push('/home')
          }
        })
        .catch((errors) => {
          this.message = errors.response.data.errors.text
        })
    },
    redirect: function (e) {
      e.preventDefault()
      router.push('/')
    },
    beforeDestroy: function () {
      clearTimeout(this.idTimer)
    }
  },
  computed: {
    formatDateEn(value) {
      if (value) {
        return moment.utc(String(value)).format('YYYY-MM-DD')
      } else {
        return null
      }
    }
  },
  mounted () {
    document.title = 'Initialisation du compte'

    this.getUserData()
  }
}
</script>

<template>
  <BasicModal v-if="showedModal">
    <template v-slot:header>
      Supports - {{ userToEdit.prenom + " " + userToEdit.nom }}</template
    >
    <template v-slot:body>
      <div class="modal-body-content">
        <div class="container-tabs">
          <nav class="themes-tabs">
            <div
              v-for="(theme, index) in themes"
              :key="theme.id"
              class="tab-titre"
              v-on:click="showTab(index)"
              :class="{ unselected: selectedTabIndex != index }"
            >
              {{ theme.titre }}
            </div>
          </nav>

          <div class="selecters-container">
            <button
              title="Aucun"
              class="button-invisible"
              @click="selectAllVisibilities(1)"
            >
              <i class="icon-invalidate"></i>
            </button>

            <button
              title="Restreint"
              class="button-invisible"
              @click="selectAllVisibilities(2)"
            >
              <i class="icon-connexion"></i>
            </button>

            <button
              title="Complet"
              class="button-invisible"
              @click="selectAllVisibilities(3)"
            >
              <i class="icon-validate"></i>
            </button>

            <div class="selecter-container-send-reponses">
              <button
                title="Envoyer réponses"
                class="button-invisible"
                @click="toggleSelectionTheme(selectedTabIndex)"
              >
                <i class="icon-shortcut"></i>
              </button>
            </div>
          </div>

          <div
            class="themes-container"
            v-for="theme in themes"
            :key="theme.id"
            :id="theme.id"
            v-bind:class="{ 'hidden-tab': theme.visible === false }"
          >
            <div
              class="supports-container"
              v-for="support in theme.Supports"
              :key="support.id"
            >
              <div class="support-container">
                <div class="support-container-title">
                  <div class="miniature-support-container">
                    <img
                      :src="getMiniature(support)"
                      alt=""
                      draggable="false"
                      class="miniature-support"
                      @error="errorImageLoading"
                    />
                  </div>
                  <span>{{ formatTitleSupport(support) }}</span>
                </div>

                <div class="colonne-access">
                  <input
                    class="radio-visibility"
                    type="radio"
                    :name="'support' + support.id"
                    value="invisible"
                    :checked="support.supportaccesId === 1"
                    v-on:click="changeAccess(support, 1)"
                  />
                  <input
                    class="radio-visibility"
                    type="radio"
                    :name="'support' + support.id"
                    value="accessible"
                    :checked="support.supportaccesId === 2"
                    v-on:click="changeAccess(support, 2)"
                  />
                  <input
                    class="radio-visibility"
                    type="radio"
                    :name="'support' + support.id"
                    value="accessible"
                    :checked="support.supportaccesId === 3"
                    v-on:click="changeAccess(support, 3)"
                  />

                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      :name="'corrige' + support.id"
                      v-if="support.Supporttype.titre === 'Cours' || support.Supporttype.titre === 'TD'"
                      :checked="support.selectedForCorrection"
                      v-on:click="toggleSelection(support)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <div class="container-buttons">
          <button class="buttonPrimary" @click="applyChanges()">
            Appliquer
          </button>
          <a @click="closeModal">Fermer</a>
        </div>
      </div>
    </template>
  </BasicModal>
</template>

<style src='./userSupportsModal.css' scoped></style>

<script>
import Api from "@/services/Api";
import BasicModal from "@/modals/BasicModal/BasicModal";

export default {
  name: "UserSupportsModal",
  data() {
    return {
      showedModal: false,
      userToEdit: {},
      idUser: 0,

      themes: [],
      selectedTabIndex: 0
    }
  },
  components: {
    BasicModal,
  },
  methods: {
    toggleSelectionTheme: function (tabIndex) {
      let currentTheme = this.themes[tabIndex];

      currentTheme.selectedForCorrection = !currentTheme.selectedForCorrection;
      for (let support of currentTheme.Supports) {
        support.selectedForCorrection = currentTheme.selectedForCorrection;
      }
    },
    errorImageLoading: function (event) {
      event.target.src = "/images/undefined.svg";
    },
    toggleSelection: function (support) {
      support.selectedForCorrection = !support.selectedForCorrection;
    },
    changeAccess: function (support, supportaccesId) {
      support.supportaccesId = supportaccesId;
    },
    showTab: function (tabIndex) {
      this.selectedTabIndex = tabIndex;
      this.themes.forEach(function (theme, index) {
        if (index === tabIndex) {
          theme.visible = true;
        } else {
          theme.visible = false;
        }
      });
    },
    selectAllVisibilities: function (supportaccesId) {
      for (let support of this.themes[this.selectedTabIndex].Supports) {
        support.supportaccesId = supportaccesId;
      }
    },
    formatTitleSupport: function (support) {
      if (support.titre) {
        let textTitle = support.Supporttype.titre + " : " + support.titre;

        return textTitle;
      }
    },
    getMiniature: function (support) {
      if (
        Object.prototype.hasOwnProperty.call(support, "miniatureName") &&
        support.miniatureName !== null
      ) {
        let pathImage =
          process.env.VUE_APP_CDN_PATH +
          "/supports/" +
          support.id +
          "/cover/" +
          support.miniatureName;
        return pathImage;
      } else {
        return "/images/undefined.svg";
      }
    },
    getSupportsUser: function () {
      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token"),
        },
      };

      Api()
        .get("/themes/users/" + this.idUser + "/supports", configUrl)
        .then((response) => {
          this.selectedTabIndex = 0;

          let tempThemes = response.data.themes;
          tempThemes.forEach(function (theme, index) {
            if (index === 0) {
              theme.visible = true;
            } else {
              theme.visible = false;
            }

            theme.selectedForCorrection = false;

            for (let support of theme.Supports) {
              if (support.Supportslots && support.Supportslots.length > 0) {
                support.supportaccesId = support.Supportslots[0].supportaccesId;
              } else {
                support.supportaccesId = 1;
              }
              support.selectedForCorrection = false;
            }
          });

          this.themes = tempThemes;
        })
        .catch(() => {});
    },
    showModal: function (idUser) {
      this.idUser = idUser
      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token"),
        }
      };

      this.getSupportsUser();

      Api()
        .get("/users/" + idUser, configUrl)
        .then((response) => {
          this.userToEdit = response.data.user;

          this.showedModal = true;
        })
        .catch(() => {});
    },
    applyChanges: function () {
      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token"),
        },
      };
      let idSupportsForResponses = [];
      let listSupports = [];

      for (let currentTheme of this.themes) {
        for (let support of currentTheme.Supports) {
          let infoSupport = {
            id: support.id,
            supportaccesId: support.supportaccesId,
          };
          listSupports.push(infoSupport);

          if (support.selectedForCorrection) {
            idSupportsForResponses.push(support.id);
          }
        }
      }

      let dataVisibility = {
        listSupports: listSupports,
        idUser: this.idUser,
      };

      Api()
        .post(
          "/authorizations/changeSupportsAccessForUser",
          dataVisibility,
          configUrl
        )
        .then(() => {
          alert("Modifications effectuées");
        })
        .catch(() => {});

      if (idSupportsForResponses.length > 0) {
        let data = {
          idSupports: idSupportsForResponses,
          idUser: this.idUser,
        };

        Api()
          .post("/supports/sendReponsesSupportsToUser", data, configUrl)
          .then(() => {
            alert("Réponses envoyées");

            for (let currentTheme of this.themes) {
              currentTheme.selectedForCorrection = false;
              for (let chapitre of currentTheme.Chapitres) {
                for (let support of chapitre.Supports) {
                  support.selectedForCorrection = false;
                }
              }
            }
          })
          .catch(() => {});
      }
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault();
      }
      this.showedModal = false;
    },
  },
  mounted() {
    this.emitter.on("openModalUserSupports", params => {
      this.showModal(params.idUser)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    });
  },
};
</script>

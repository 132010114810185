<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>Suppression d'utilisateur</template>

    <template v-slot:body>
      <p>Supprimer l'utilisateur <b>{{user.nom}} {{user.prenom}} ({{user.pseudo}})</b> ?</p>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class="buttonPrimary" @click="deleteUser">
          OK
        </button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../BasicModal/basicModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'DeleteUserModal',
  data () {
    return {
      idUser: 0,
      showedModal: false,
      user: {},
      configUrl: {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }
    }
  },
  components: {
    BasicModal
  },
  methods: {
    deleteUser: function () {
    
      Api().delete('/users/' + this.idUser, this.configUrl)
        .then(() => {
          this.$emit('refresh', true)
          this.showedModal = false
        })
        .catch(() => {
          this.showedModal = false
        })
    },
    showModal: function (idUser) {
      this.idUser = idUser
      this.showedModal = true
    },
    closeModal: function (e) {
      if (e != null)
        e.preventDefault()
      this.showedModal = false
    },
     getUserData: function (userId) {
      Api().get('/users/' + userId, this.configUrl)
        .then((response) => {
          this.user = response.data.user
        })
        .catch(() => {
        })
    },
  },
  mounted () {
    this.emitter.on("openModalDeleteUser", params => {
      this.showModal(params.idUser)
      this.getUserData(params.idUser)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>

import { createApp } from 'vue'
import App from './App'
import router from './router'

import TopBar from '@/components/TopBar/TopBar'
import SideBar from '@/components/SideBar/SideBar'
import TabulationChecker from '@/mixins/TabulationChecker'

import hljs from 'highlight.js/lib/core';

import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import php from 'highlight.js/lib/languages/php';
import java from 'highlight.js/lib/languages/java';
import xml from 'highlight.js/lib/languages/xml';
import sql from 'highlight.js/lib/languages/sql';
import css from 'highlight.js/lib/languages/css';
import shell from 'highlight.js/lib/languages/shell';

import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', json);
hljs.registerLanguage('php', php);
hljs.registerLanguage('java', java);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('css', css);
hljs.registerLanguage('shell', shell);

import mitt from 'mitt';
const emitter = mitt();

const app = createApp({
  extends: App,
  components: {
    TopBar,
    SideBar
  }
})

app.config.globalProperties.emitter = emitter;
app.use(router)
app.use(hljsVuePlugin)
app.mixin(TabulationChecker);

app.mount('#app')
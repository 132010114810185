<template>
  <div class='page-background'>
    <form id='form' class='fadeInDown' v-on:submit='resetPassword'>
      <div class='title-form'>
        Mot de passe oublié ?
      </div>

      <div class='container-field'>
        <label class='label_champ'>Identifiant :</label>
        <input type='text' class='textfield_champ' name='pseudo' required/>
      </div>

      <div class='container-field'>
        <label class='label_champ'>Date de naissance :</label>
        <input spellcheck='false' class='textfield_champ' type='date' v-model='mydate' name='dateNaissance' placeholder='JJ/MM/AAAA' autocomplete='off' required>
      </div>

      <label v-bind:class='{ "error-text" : error === true }'>{{ message }}</label>

      <div class='container-buttons'>
        <a href='/' v-on:click='redirect'>Retour</a>
        <input type='submit' class='buttonPrimary' value='Envoyer'/>
      </div>
    </form>
  </div>
</template>

<style src='./forgotPasswordPage.css' scoped></style>

<script>
import Api from '@/services/Api'
import router from '@/router'
export default {
  name: 'ForgotPasswordPage',
  data () {
    return {
      error: false,
      message: '',
      mydate: '01-01-2000'
    }
  },
  methods: {
    resetPassword: function (e) {
      e.preventDefault()

      let data = {
        pseudo: e.target.elements.pseudo.value,
        dateNaissance: e.target.elements.dateNaissance.value
      }
      let self = this

      Api().post('/users/password-reset', data)
        .then((response) => {
          self['message'] = response.data.message
          self['error'] = false
        })
        .catch((errors) => {
          self['message'] = errors.response.data.message
          self['error'] = true
        })
    },
    redirect: function (e) {
      e.preventDefault()
      router.push('/')
    } 
  },
  mounted () {
    document.title = 'Mot de passe oublié'
  }
}
</script>

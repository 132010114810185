<template>
  <BasicModal v-if="showedModal">
    <template v-slot:header>
      Accès utilisateur ( {{ formatTitleSupport(supportToEdit) }} )
    </template>
    <template v-slot:body>
      <div>
        <div class="container-columns">
          <div class="panel-slots">
            <span class="title-panel-slot">Aucun</span>
            <div>
              <span>Sélectionner : </span>
              <div class="container-groupes-selecters">
                <span
                  v-for="groupe in groupes"
                  :key="groupe.id"
                  v-on:click="toggleGroup(groupe, slotlessUsers)"
                  class="groupe-selecter"
                  :title="groupe.titre"
                  :style="formatBackground(groupe)"
                  >{{ groupe.titre }}</span
                >
              </div>
            </div>

            <div class="container-select">
              <label>Déplacer sélection vers : </label>
              <select
                v-if="supportacces && supportacces.length > 0"
                v-on:change="changeUsersSupportAccess($event, slotlessUsers)"
              >
                <option value="0" selected disabled hidden>---</option>
                <option value="2">{{ supportacces[1].titre }}</option>
                <option value="3">{{ supportacces[2].titre }}</option>
              </select>
            </div>

            <div class="container-slots">
              <div
                class="item-slot"
                v-for="user in slotlessUsers"
                :key="user.id"
                :title="user.prenom + ' ' + user.nom"
                v-on:click="toggleUser(user.id, slotlessUsers)"
                :style="formatBackground(user.Groupe)"
                :class="{ 'selected-item-slot': user.checked == true }"
              >
                <input type="checkbox" v-model="user.checked" />
                <label>
                  <div class="last-name">{{ formatStudentLastName(user) }}</div>
                  <div class="first-name">
                    {{ formatStudentFirstName(user) }}
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="panel-slots">
            <span class="title-panel-slot">Restreint</span>
            <div>
              <span>Sélectionner : </span>
              <div class="container-groupes-selecters">
                <span
                  v-for="groupe in groupes"
                  :key="groupe.id"
                  v-on:click="toggleGroup(groupe, slotRestrictedUsers)"
                  class="groupe-selecter"
                  :title="groupe.titre"
                  :style="formatBackground(groupe)"
                  >{{ groupe.titre }}</span
                >
              </div>
            </div>

            <div class="container-select">
              <label>Déplacer sélection vers : </label>
              <select
                v-if="supportacces && supportacces.length > 0"
                v-on:change="
                  changeUsersSupportAccess($event, slotRestrictedUsers)
                "
              >
                <option value="0" selected disabled hidden>---</option>
                <option value="1">{{ supportacces[0].titre }}</option>
                <option value="3">{{ supportacces[2].titre }}</option>
              </select>
            </div>

            <div class="container-slots">
              <div
                class="item-slot"
                v-for="user in slotRestrictedUsers"
                :key="user.id"
                :title="user.prenom + ' ' + user.nom"
                v-on:click="toggleUser(user.id, slotRestrictedUsers)"
                :style="formatBackground(user.Groupe)"
                :class="{ 'selected-item-slot': user.checked == true }"
              >
                <input type="checkbox" v-model="user.checked" />
                <label>
                  <div class="last-name">{{ formatStudentLastName(user) }}</div>
                  <div class="first-name">
                    {{ formatStudentFirstName(user) }}
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="panel-slots">
            <span class="title-panel-slot">Complet</span>
            <div>
              <span>Sélectionner : </span>
              <div class="container-groupes-selecters">
                <span
                  v-for="groupe in groupes"
                  :key="groupe.id"
                  v-on:click="toggleGroup(groupe, slotCompleteUsers)"
                  class="groupe-selecter"
                  :title="groupe.titre"
                  :style="formatBackground(groupe)"
                  >{{ groupe.titre }}</span
                >
              </div>
            </div>

            <div class="container-select">
              <label>Déplacer sélection vers : </label>
              <select
                v-if="supportacces && supportacces.length > 0"
                v-on:change="
                  changeUsersSupportAccess($event, slotCompleteUsers)
                "
              >
                <option value="0" selected disabled hidden>---</option>
                <option value="1">{{ supportacces[0].titre }}</option>
                <option value="2">{{ supportacces[1].titre }}</option>
              </select>
            </div>

            <div class="container-slots">
              <div
                class="item-slot"
                v-for="user in slotCompleteUsers"
                :key="user.id"
                :title="user.prenom + ' ' + user.nom"
                v-on:click="toggleUser(user.id, slotCompleteUsers)"
                :style="formatBackground(user.Groupe)"
                :class="{ 'selected-item-slot': user.checked == true }"
              >
                <input type="checkbox" v-model="user.checked" />
                <label>
                  <div class="last-name">{{ formatStudentLastName(user) }}</div>
                  <div class="first-name">
                    {{ formatStudentFirstName(user) }}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
    </template>
  </BasicModal>
</template>

<style src='./supportAccessModal.css' scoped></style>

<script>
import Api from "@/services/Api";
import BasicModal from "@/modals/BasicModal/BasicModal";

export default {
  data() {
    return {
      idSupportRights: 0,
      showedModal: false,
      supportToEdit: {},
      slotlessUsers: [],
      slotRestrictedUsers: [],
      slotCompleteUsers: [],

      supportacces: [],

      groupes: [],
    };
  },
  components: {
    BasicModal,
  },
  methods: {
    toggleUser: function (userId, listeUsers) {
      for (let user of listeUsers) {
        if (user.id === userId) {
          user.checked = !user.checked;
        }
      }
    },
    toggleGroup: function (groupe, listeUsers) {
      for (let noSlotUser of listeUsers) {
        if (noSlotUser.Groupe.id === groupe.id) {
          noSlotUser.checked = !noSlotUser.checked;
        }
      }
    },
    formatBackground: function (groupe) {
      let backgroundColor = "grey";
      if (groupe) {
        backgroundColor = groupe.couleur;
      }
      return "border-color:" + backgroundColor + ";";
    },
    formatTitleSupport: function (support) {
      if (support.titre) {
        let textTitle = support.Supporttype.titre + " : " + support.titre;

        return textTitle;
      }
    },
    formatStudentLastName: function (student) {
      if (student.nom) {
        let textStudent = student.nom.replace("-", " ").substring(0, 12);

        return textStudent;
      }
    },
    formatStudentFirstName: function (student) {
      if (student.prenom) {
        let textStudent = student.prenom.replace("-", " ").substring(0, 12);

        return textStudent;
      }
    },
    refreshSupportSlots: function () {
      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token"),
        },
        params: {
          idSupport: this.idSupportRights
        }
      };

      Api()
        .get("/users/groups", configUrl)
        .then((response) => {
          this.groupes = response.data.groups;
        })
        .catch(() => {});

      Api()
        .get("/authorizations/getSupportslots", configUrl)
        .then((response) => {
          this.slotRestrictedUsers = [];
          this.slotCompleteUsers = [];

          for (let slot of response.data.slots) {
            let slotUser = slot.Utilisateur;
            slotUser.checked = false;

            if (slot.supportaccesId === 2) {
              this.slotRestrictedUsers.push(slotUser);
            } else if (slot.supportaccesId === 3) {
              this.slotCompleteUsers.push(slotUser);
            }
          }
        })
        .catch(() => {});

      Api()
        .get("/authorizations/getUsersWithoutSlot", configUrl)
        .then((response) => {
          this.slotlessUsers = [];
          for (let noSlotUser of response.data.users) {
            noSlotUser.checked = false;
          }
          this.slotlessUsers = response.data.users;
        })
        .catch(() => {});
    },
    showModal: function (idSupportRights) {
      this.idSupportRights = idSupportRights
      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token"),
        },
        params: {
          idSupport: idSupportRights
        }
      };

      this.refreshSupportSlots();

      Api()
        .get("/supports/getSupportForEdition", configUrl)
        .then((response) => {
          this.supportToEdit = response.data.support;

          this.showedModal = true;
        })
        .catch(() => {});

      Api()
        .get("/supportacces", {
          headers: {
            authorization: "Token " + localStorage.getItem("user-token"),
          },
        })
        .then((response) => {
          this.supportacces = response.data.supportacces;
        })
        .catch(() => {});
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.showedModal = false
    },
    changeUsersSupportAccess: function (event, listUsers) {
      let supportaccesId = event.target.options[event.target.options.selectedIndex].value;

      // reset list value
      event.target.options.selectedIndex = 0

      let configUrl = {
        headers: {
          authorization: "Token " + localStorage.getItem("user-token")
        }
      };

      let listeToSend = [];
      for (let slotUser of listUsers) {
        if (slotUser.checked) {
          listeToSend.push(slotUser);
        }
      }
      let data = {
        listUsers: listeToSend,
        idSupport: this.supportToEdit.id,
        supportaccesId: supportaccesId,
      };
      Api()
        .post("/authorizations/changeUsersSupportAccess", data, configUrl)
        .then(() => {
          this.refreshSupportSlots();
        })
        .catch(() => {});
    },
  },
  mounted () {
    this.emitter.on("openModalSupportAccess", params => {
      this.showModal(params.idSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>

<template>
  <div class='container-areahtml'>
    <div class='areahtml-input-container'>
      <div class='title-area'>HTML</div>
      <textarea v-on:keydown='tabulationFormat' spellcheck='false' autocomplete='off' class='areahtml-input' ref="areaHTML" @blur='saveReponse($event, currentBlock.id)' @input='updateBlockSize($event)'></textarea>
    </div>

    <div class='areahtml-input-container'>
      <iframe class='areahtml-test' ref="frameHTML"></iframe>
    </div>
  </div>
</template>

<style src='./areaHtml.css' scoped></style>

<script>
import router from '@/router'
import Api from '@/services/Api'

export default {
  name: 'AreaHtml',
  props: {
    currentBlock: {}
  },
  data () {
    return {
    }
  },
  methods: {
    redirectToPage: function (event, pageName) {
      event.preventDefault()

      if (router.currentRoute.path !== '/' + pageName) {
        router.push('/' + pageName)
      }
    },
    getReponse: function (block) {
      if (block.Reponses[0] && block.Reponses[0].contenu) {
        return block.Reponses[0].contenu
      }
    },
    updateFrameContent: function (HTMLText) {
      let ifrm = this.$refs['frameHTML'].contentDocument
      let CSSText = 'body {color: grey; margin: 5px;}'

      let frameContent = '<!DOCTYPE html><html><head></head><body></body></html>'
      frameContent += HTMLText
      frameContent += '<style>' + CSSText + '</style>'
  
      ifrm.write('');
      ifrm.open();
      ifrm.write(frameContent);
      ifrm.close();
    },
    resizeBlock: function () {
      this.$nextTick(() => {
        let eltHTML = this.$refs['areaHTML']
        let frameHTML = this.$refs['frameHTML']
        eltHTML.style.height = 0
        frameHTML.style.height = 0

        let newHeight = eltHTML.scrollHeight + 2
        if (frameHTML.contentDocument.body.scrollHeight > newHeight ) {
          newHeight = frameHTML.contentDocument.body.scrollHeight
        }

        eltHTML.style.height = newHeight + "px"
        this.$refs['frameHTML'].style.height = newHeight + "px"
      })
    },
    updateBlockSize: function (event) {
      this.updateFrameContent(event.target.value)
      this.resizeBlock()
    },
    saveReponse: function (event, idBlock) {
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }
      let data = {
        idBlock: idBlock,
        content: event.target.value
      }

      Api().post('/blocks/saveReponse', data, configUrl)
        .then(() => {
          this.$emit('refresh', true)

          this.updateFrameContent(event.target.value)
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    let htmlContent = ''
    if (this.currentBlock.Reponses && this.currentBlock.Reponses[0]) {
      htmlContent = this.currentBlock.Reponses[0].contenu
      this.$refs['areaHTML'].value = htmlContent
      this.updateFrameContent(htmlContent)
    }
    this.resizeBlock()
  }
}
</script>

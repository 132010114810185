<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-content">
              <h3>
                <slot name="header"></slot>
              </h3>
              <button class="buttonPrimary" @click="closeModal">
                X
              </button>
            </div>
          </div>

          <div class="modal-body">
            <div class="modal-body-content">
              <slot name="body"> </slot>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style src='./basicModal.css' scoped></style>

<script>
export default {
  name: "BasicModal",
  methods: {
    closeModal: function () {
      this.emitter.emit("closedModal", true);
    }
  }
}
</script>

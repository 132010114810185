<template>
  <header class='container-bar'>
    <div class='left-bar'>
      <BurgerMenu></BurgerMenu>
      <a class='link-home' href='/home' v-on:click='redirectToPage($event, "home")' draggable="false">
        <img class='img-site' src='/images/logo-img.png' alt='' draggable="false">
        <span class='logo-text'>Hiteach</span>
      </a>
    </div>

    <div class='right-bar'>
      <button class="button-top" @click='switchTheme'>
        <i v-bind:class='currentTheme === "dark" ? "icon-dark-theme" : "icon-light-theme" '></i>
      </button>
      <button class="button-top" @click='switchMode' v-if="isTeacher">
        <i v-bind:class='currentMode === "reading" ? "icon-caret" : "icon-edit" '></i>
      </button>
      <button class="button-top" @click='switchAreaVisibility' v-if="isTeacher">
        <i v-bind:class='currentAnswerVisibility === "hidden" ? "icon-eye-blocked" : "icon-eye" '></i>
      </button>

      <a href="/profil" class='container-name link' v-on:click='redirectToProfil($event, "profil")'><i class="icon-user button-profil"></i> {{ user.nom }} {{ user.prenom }}</a>
    </div>
  </header>
</template>

<style src='./topBar.css' scoped></style>

<script>
import Api from '@/services/Api'
import Auth from '@/mixins/Auth'

import BurgerMenu from "@/components/BurgerMenu/BurgerMenu.vue";

import router from '@/router'

export default {
  name: 'TopBar',
  components: {
    BurgerMenu
    },
  data () {
    return {
      user: {
      },
      currentTheme: 'light',
      currentMode: 'reading',
      currentAnswerVisibility: 'visible',
      isTeacher: false
    }
  },
  created: async function () {
    let auth = await Auth.methods.getAuthenticationStatus()
    this.isTeacher = auth.typeUser === 'teacher'
  },
  methods: {
    getUserData: function () {
      let self = this

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().get('/users/current', configUrl)
        .then((response) => {
          self['user'] = response.data.user
        })
        .catch(() => {
          router.push('/')
        })
    },
    setTheme: function (themeMod) {
      if (themeMod == 'light') {
        localStorage.setItem('theme', themeMod)
      } else {
        localStorage.setItem('theme', 'dark')
      }
      this.currentTheme = themeMod
      document.documentElement.setAttribute('data-theme', themeMod)
    },
    switchTheme: function () {
      if (this.currentTheme === 'dark') {
        this.setTheme('light')
      } else {
        this.setTheme('dark')
      }
    },
    switchMode: function () {
      if (this.currentMode === 'editing') {
        localStorage.setItem('page-mode', 'reading')
      } else {
        localStorage.setItem('page-mode', 'editing')
      }
      this.currentMode = localStorage.getItem('page-mode')
      this.emitter.emit("changeMode", this.currentMode);
    },
    switchAreaVisibility: function () {
      if (this.currentAnswerVisibility === 'showed') {
        localStorage.setItem('answer-visibility', 'hidden')
      } else {
        localStorage.setItem('answer-visibility', 'showed')
      }
      this.currentAnswerVisibility = localStorage.getItem('answer-visibility')
      this.emitter.emit("changeAnswerVisibility", this.currentAnswerVisibility);
    },
    redirectToPage: function (event, pageName) {
      event.preventDefault()

      if (router.currentRoute.path !== '/' + pageName) {
        router.push('/' + pageName)
      }
    },
    redirectToProfil: function (event, pageName) {
      event.preventDefault()

      if (router.currentRoute.path !== '/' + pageName) {
        router.push('/' + pageName)
      }
    }
  },
  mounted () {
    this.getUserData()
    this.currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null
    document.documentElement.setAttribute('data-theme', this.currentTheme)

    this.setTheme(this.currentTheme)

    this.currentMode = localStorage.getItem('page-mode') ? localStorage.getItem('page-mode') : 'reading'
    this.currentAnswerVisibility = localStorage.getItem('answer-visibility') ? localStorage.getItem('answer-visibility') : 'showed'
  }
}
</script>


export function tabulationFormat (e) {
  var keys = {}
  keys[e.keyCode] = e.type === 'keydown'

  if (keys[9]) {
    e.preventDefault()
    var cible = e.target

    var tabString = String.fromCharCode(9)

    var beforeSelection = cible.value.substring(0, cible.selectionStart)
    var selection = cible.value.substring(cible.selectionStart, cible.selectionEnd)
    var afterSelection = cible.value.substring(cible.selectionEnd)

    var lines = selection.split('\n')

    var newSelection = ''

    if (keys[9] && window.event.shiftKey) {
      if (beforeSelection.endsWith(tabString)) {
        beforeSelection = beforeSelection.substr(0, beforeSelection.length - 1)
      }

      cible.value = beforeSelection

      for (var indexLine in lines) {
        var line = lines[indexLine]

        if (line.startsWith(tabString)) {
          line = line.substr(1)
        }

        newSelection += line

        if (indexLine < lines.length - 1 && lines.length !== 1) {
          newSelection += '\n'
        }
      }

      cible.value += newSelection
      cible.value += afterSelection

      cible.setSelectionRange(beforeSelection.length, beforeSelection.length + newSelection.length)

      cible.focus()
    } else {
      cible.value = beforeSelection

      for (var indexLigne in lines) {
        var ligne = lines[indexLigne]

        newSelection += tabString + ligne

        if (indexLigne < lines.length - 1 && lines.length !== 1) {
          newSelection += '\n'
        }
      }

      cible.value += newSelection
      cible.value += afterSelection

      cible.setSelectionRange(beforeSelection.length + tabString.length, beforeSelection.length + newSelection.length)

      cible.focus()
      return false
    }
  }
}

export default {
  methods: {
    tabulationFormat
  }
}

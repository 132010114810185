<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>Edition d'un bloc</template>

    <template v-slot:body> 
      <form id='form-modal' ref='form'>
        <div class='form-content'>

          <input type='hidden' name='idBlock' :value='blockToEdit.id'>

          <label class='label-champ'>Contenu : </label>
          <div class='textarea-champ'>
             <QuillEditor v-model:content="content" contentType="html" :options="editorOption" name='content' placeholder='fff' ref="myQuillEditor" spellcheck='false'/>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' name='order' placeholder='1' autocomplete='off' required :value='blockToEdit.ordre'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Accès limité </label>
            <input class='textfield-champ' type='checkbox' name='limitedAccess' :checked='blockToEdit.limitedAccess'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Type de block : </label>
            <div class="formfield-select--container">
              <select name='idBlocktype'>
                <option v-for="bloctype in bloctypes" :key="bloctype.id" :value='bloctype.id' :selected='bloctype.id == blockToEdit.bloctypeId'>
                  {{ bloctype.titre }}
                </option>
              </select>
            </div>
          </div>

        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class='buttonPrimary' @click='editBlock'>Valider</button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>

  </BasicModal>
</template>

<style src='../CreateBlockModal/createBlockModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@/components/QuillEditor/quillEditor.css'
import { QuillEditor } from '@vueup/vue-quill'

export default {
  name: 'EditBlockModal',
  data () {
    return {
      showedModal: false,
      blockToEdit: {},
      idSupport: 0,
      content: '',
      bloctypes: [],

      editorOption: {
        modules: {
          'toolbar': [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }],
            ['blockquote', 'code-block', 'variable'],
            [{ 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
          ]
        }
      }
    }
  },
  components: {
    BasicModal,
    QuillEditor
  },
  methods: {
    editBlock: function (e) {
      e.preventDefault()

      let data = {
        id: this.$refs.form.elements.idBlock.value,
        content: this.content,
        order: this.$refs.form.elements.order.value,
        limitedAccess: this.$refs.form.elements.limitedAccess.checked,
        idBlocktype: this.$refs.form.elements.idBlocktype.value
      }

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks/editBlock', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    showModal: async function (idSupport, idBlockToEdit) {
      this.idSupport = idSupport
      this.idBlockToEdit = idBlockToEdit

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: this.idSupport,
          idBlock: this.idBlockToEdit
        }
      }

      Api().get('/blocks/getBlockForEdition', configUrl)
        .then((blockResponse) => {
          Api().get('/blocks/getBlocktypes', configUrl)
            .then((response) => {
              this.blockToEdit = blockResponse.data.block
              this.content = this.blockToEdit.contenu
              this.bloctypes = response.data.bloctypes
              this.showedModal = true
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalEditBlock", params => {
      this.showModal(params.idSupport, params.idBloc)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>

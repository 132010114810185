<template>
  <TopBar v-if='isInsideApplication()'/>

  <main class='main-container' v-bind:class='{ "full-page" : !isInsideApplication()}'>
    <SideBar v-if='displaySideBar()'>
    </SideBar>
    <router-view/>
  </main>
</template>

<style>
@import 'assets/css/general.css';
@import 'assets/css/scrollbar.css';
@import 'assets/css/buttons.css';
@import 'assets/css/icons.css';

@import 'assets/css/code_theme_dark.css';
@import 'assets/css/code_theme_light.css';
</style>

<script>

import router from '@/router'

export default {
  name: 'App',
  methods: {
    isInsideApplication: () => {
      const currentRoutePath = router.currentRoute.value.path
      if (currentRoutePath !== '/' && currentRoutePath !== '/forgotPassword' && currentRoutePath !== '/initAccount') {
        return true
      }
      return false
    },
    displaySideBar: () => {
      const currentRoutePath = router.currentRoute.value.path
      if (currentRoutePath !== '/' && currentRoutePath !== '/forgotPassword' && currentRoutePath !== '/initAccount') {
        return true
      }
      return false
    }
  },
  async mounted () {
    let currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null

    if (currentTheme) {
      document.documentElement.setAttribute('data-theme', currentTheme)
    }
  } 
}
</script>

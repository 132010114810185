<template>
  <div class="page-errorLocation">
    <div v-if="notFound()" class="container-notfound">
      <div class="text-notfound">
        <img src="/images/notfound.svg" alt="" />
        <span>Page non trouvée, </span
        ><a v-on:click="redirect">retour à l'accueil</a>
      </div>
    </div>

    <div v-else class="container-forbidden">
        <img src="/images/forbidden.svg" alt="" />
        <div class="text-notfound">
          <span>Page non autorisée, </span><a v-on:click="redirect">retour à l'accueil</a>
        </div>
    </div>
  </div>
</template>

<style src='./errorLocationPage.css' scoped></style>

<script>
import router from "@/router";

export default {
  name: "ErrorLocationPage",
  data() {
    return {
      error: false,
    };
  },
  methods: {
    notFound: function () {
      if (this.$route.path == "/403") {
        return false
      } else {
        return true
      }
    },
    redirect: function () {
      if (router.currentRoute.path !== "/home") {
        router.push("/home")
      }
    }
  },
  mounted () {
    document.title = 'Hiteach'
  }
};
</script>

import {createRouter, createWebHistory} from 'vue-router'
import LoginPage from '@/pages/LoginPage/LoginPage'
import initAccountPage from '@/pages/InitAccountPage/InitAccountPage'
import HomePage from '@/pages/HomePage/HomePage'
import ListUsersPage from '@/pages/ListUsersPage/ListUsersPage'
import ForgotPasswordPage from '@/pages/ForgotPasswordPage/ForgotPasswordPage'
import ProfilPage from '@/pages/ProfilPage/ProfilPage'
import ErrorLocationPage from '@/pages/ErrorLocationPage/ErrorLocationPage'
import SupportPage from '@/pages/SupportPage/SupportPage'
import ThemesPage from '@/pages/ThemesPage/ThemesPage'
import Auth from '@/mixins/Auth'


const checkAuthentification = async (destination, from, next) => {
  if (destination.query.pseudo && destination.query.pwd) {
    let data = {
      pseudo: destination.query.pseudo,
      password: destination.query.pwd,
      urlAuthentification: true
    }
    try {
      localStorage.setItem('nextPage', destination.path)
      await Auth.methods.login(data)
    } catch (errors) {
      router.push('/')
    }
  }

  let status = await Auth.methods.getAuthenticationStatus()
  
  if (status.authenticated === true && status.authorized === true) {
    if (status.validated === true) {
      let nextPage = localStorage.getItem('nextPage')
      if (nextPage) {
        localStorage.removeItem('nextPage')
        next(nextPage)
      } else {
        next()
      }
    } else {
      next('/initAccount')
    }
  } else {
    localStorage.setItem('nextPage', destination.path)
    next('/')
  }
}

const checkValidation = async (to, from, next) => {
  let status = await Auth.methods.getAuthenticationStatus()

  if (status.authenticated === true && status.authorized === true) {
    if (status.validated === true) {
      next('/home')
    } else {
      next()
    }
  } else {
    next('/')
  }
}

const checkProfilAccess = async (to, from, next) => {
  let status = await Auth.methods.getAuthenticationStatus()

  if (status.authenticated === true && status.authorized === true) {
    next()
  } else {
    next('/')
  }
}

const checkAuthentificationAsTeacher = async (to, from, next) => {
  let status = await Auth.methods.getAuthenticationStatus()

  if (status.authenticated === true && status.authorized === true && status.typeUser === 'teacher') {
    next()
  } else {
    next('/home')
  }
}

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/initAccount',
    name: 'initAccountPage',
    component: initAccountPage,
    beforeEnter: checkValidation
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPasswordPage',
    component: ForgotPasswordPage
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    beforeEnter: checkAuthentification
  },
  {
    path: '/profil',
    name: 'ProfilPage',
    component: ProfilPage,
    beforeEnter: checkProfilAccess
  },
  {
    path: '/listUsers',
    name: 'ListUsersPage',
    component: ListUsersPage,
    beforeEnter: checkAuthentificationAsTeacher
  },
  {
    path: '/supports/:theme/:course',
    name: 'SupportPage',
    component: SupportPage,
    beforeEnter: checkAuthentification
  },
  {
    path: '/supports/:theme',
    name: 'ThemesPage',
    component: ThemesPage,
    beforeEnter: checkAuthentification
  },
  {
    path: "/:catchAll(.*)",
    name: 'ErrorLocationPage',
    component: ErrorLocationPage,
    beforeEnter: checkAuthentification
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>Edition d'une zone CSS</template>

    <template v-slot:body>
      <form id='form-modal' ref='form'>
        <div class='form-content'>

          <input type='hidden' name='idBlock' :value='blockToEdit.id'>
          <div class='area-container'>
            <div class='area-content'>
              <label class='label-champ'>HTML : </label>
              <textarea class='textarea-champ' name='areaHTML' v-on:keydown='tabulationFormat' :value='blockToEdit.contenu'></textarea>
            </div>

            <div class='area-content'>
              <label class='label-champ'>CSS : </label>
              <textarea class='textarea-champ' name='areaCSS' v-on:keydown='tabulationFormat' :value='blockToEdit.contenuCSS'></textarea>
            </div>
          </div>

          <div class='area-content'>
            <label class='label-champ'>Réponse par défaut : </label>
            <textarea class='textarea-champ' v-on:keydown='tabulationFormat' name='areaDefault' :value='blockToEdit.defaultCSSValue'></textarea>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' name='order' placeholder='1' autocomplete='off' required :value='blockToEdit.ordre'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Hauteur : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='100' name='height' placeholder='1' autocomplete='off' required :value='blockToEdit.hauteur'>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class='buttonPrimary' @click='editBlock'>Valider</button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../CreateAreaCssModal/createAreaCssModal.css' scoped></style>

<script>

import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'EditAreaCssModal',
  data () {
    return {
      idSupport: 0,
      idBlockToEdit: 0,
      showedModal: false,
      blockToEdit: {}
    }
  },
  components: {
    BasicModal
  },
  methods: {
    editBlock: function (e) {
      e.preventDefault()

      let data = {
        content: this.$refs.form.elements.areaHTML.value,
        contentCSS: this.$refs.form.elements.areaCSS.value,
        defaultCSSValue: this.$refs.form.elements.areaDefault.value,
        height: this.$refs.form.elements.height.value,
        order: this.$refs.form.elements.order.value,
        id: this.$refs.form.elements.idBlock.value
      }

      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks/editBlock', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.$emit('closedModal', true)
      this.showedModal = false
    },
    showModal: function (idSupport, idBlockToEdit) {
      this.idSupport = idSupport
      this.idBlockToEdit = idBlockToEdit
      let configUrl = {
        headers: {
          authorization: 'Token ' + localStorage.getItem('user-token')
        },
        params: {
          idBlock: idBlockToEdit
        }
      }

      Api().get('/blocks/getBlockForEdition', configUrl)
        .then((blockResponse) => {
          this.blockToEdit = blockResponse.data.block
          this.showedModal = true
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    this.emitter.on("openModalEditAreaCSS", params => {
      this.showModal(params.idSupport, params.idBloc)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>

<template>
  <BasicModal v-if="showedModal">
    <template v-slot:header>
      Importation utilisateurs depuis excel
    </template>

    <template v-slot:body>
      <form id="form-connexion" ref="form">
        <div class="form-content">
          <div class="infos-import">
            <p>
              La première ligne du fichier sera ignorée. <br />Les colonnes de
              nom, prénom et pseudo seront prises en compte.
            </p>
          </div>

          <div class="container-champ">
            <label class="label-champ">Ficher excel : </label>
            <div>
              <input type="file" id="dataFile" name="dataFile" />
            </div>
          </div>

          <div class="container-champ">
            <label class="label-champ">Groupe : </label>
            <div>
              <select class="" name="groupId">
                <option
                  v-for="group in groups"
                  :key="group.id"
                  :value="group.id"
                >
                  {{ group.titre }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class="buttons-container">
        <button @click="submitFile" class="buttonPrimary">Valider</button>
        <a @click="closeModal(e)"> Annuler </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='./importUsersModal.css' scoped></style>

<script>
import Api from "@/services/Api";
import BasicModal from "@/modals/BasicModal/BasicModal";

export default {
  name: "ImportUsersModal",
  data() {
    return {
      showedModal: false,
      groups: [],
    };
  },
  components: {
    BasicModal,
  },
  methods: {
    getGroups: function () {
      let configUrl = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Token " + localStorage.getItem("user-token"),
        },
      };
      Api()
        .get("/users/groups", configUrl)
        .then((response) => {
          this.groups = response.data.groups;
        })
        .catch(() => {});
    },
    submitFile: async function () {
      if (
        !this.$refs.form.elements.dataFile.files[0] ||
        this.$refs.form.elements.dataFile.files[0] === null
      ) {
        return;
      }

      let configUrl = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Token " + localStorage.getItem("user-token"),
        },
      };

      const formData = new FormData();
      formData.append("dataFile", this.$refs.form.elements.dataFile.files[0]);
      formData.append("groupId", this.$refs.form.elements.groupId.value);

      Api()
        .post("/users/import", formData, configUrl)
        .then(() => {
          this.closeModal(null);
          this.$emit("refresh", true);
        })
        .catch(() => {
          this.closeModal(null);
          this.$emit("refresh", true);
        });
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault();
      }
      this.$emit("closedImportUsersModal", true);
      this.showedModal = false;
    },
    showModal: function () {
      this.showedModal = true;
    },
  },
  watch: {
    showImportUsersModal: function () {
      if (this.showImportUsersModal === true) {
        this.showModalImport();
      }
    },
  },
  mounted() {
    this.getGroups()

    this.emitter.on("openModalImportUsers", () => {
      this.showModal()
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null)
    })
  }
}
</script>
